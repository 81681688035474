.tenant-properties-list {
  max-height: calc(100vh - 180px);
  overflow : auto;

}

.tenant-properties-edit-form
{

  .tenantVarTextInput
  {
    border: none  !important;
    height:150px;
    min-height:150px;
    max-height:150px;
    overflow: auto;
  }

  .jsonInvalid
  {
    color: red;
  }

  .jsonValid
  {
    color: green;
  }

  .tenantVarContainer
  {
    margin-top: 5px;
  }

}
