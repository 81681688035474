.crudTable {

  #customSummaryEditForm {
    md-input-container label {
      min-width: 200px;
    }
  }
  .itemList {
    max-height: calc(100vh - 100px);
    overflow : auto;
  }

  .crudTableContainer {
    min-height: calc(100vh - 240px);
  }
}


