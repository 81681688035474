
#toolbar {
  height: $toolbarHeight;
  min-height: $toolbarHeight;
  max-height: $toolbarHeight;
  z-index: 55 !important;

  #navigation-toggle {
    width: $toolbarHeight;
    min-width: $toolbarHeight;
    height: $toolbarHeight;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }

  #user-info {
    height: $toolbarHeight;
    padding: 0;

    .username {
      margin : 5px 10px 0 0;
    }
  }

  #quick-panel-toggle {
    width: $toolbarHeight;
    height: $toolbarHeight;
    margin: 0;
    border-radius: 0;
  }

  .toolbar-separator {
    height: $toolbarHeight;
    width: 1px;
    background: lightgray;
  }
}
