/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
.campcard:hover {
  background-color: #eeeeee !important;
  cursor: pointer;
}

.campcard {
  outline: none;
  width: 280px;
  height: 420px;
}
.campcardBroken {
  outline: none;
  width: 265px;
  height: 420px;
}
.imageCheckError{
  color: rgb(221,44,0);
  font-size: 12px
}

.campcard .playbookAltMsg {
  color: red;
}

.campcard .campIcon {
  background-color: #eeeeee;
}

.campcard .campcardTitle {
  height: 64px;
}

.campcard .campchanTitle {
  height: 48px;
}
.vegaCardText {
  color: #9E9E9E;
  word-break: break-word;
}

.vegaCardHeadline {
  color: #424242;
}


// injector
@import "scss/global.scss";
@import "components/UniversalCRUDTable/universal-crud-table.scss";
@import "il8n/validations/validation.scss";
@import "main/360/customer360.scss";
@import "main/authentication/authentication.module.scss";
@import "main/compaction/compaction.module.scss";
@import "main/connector/connector.module.scss";
@import "main/content-models/content-models.scss";
@import "main/dqe/dqe-editor.module.scss";
@import "main/looker/looker.module.scss";
@import "main/mapping-templates/mapping-templates.scss";
@import "main/overrides/overrides.scss";
@import "main/provisioner/provisioner.module.scss";
@import "main/squery-templates/squery-templates.scss";
@import "main/status/status.module.scss";
@import "main/tenantProperties/tenant-properties.scss";
@import "main/udmp/udmp.scss";
@import "main/user/user.scss";
@import "main/workflow/workflow.module.scss";
@import "menu/header/header.scss";
@import "menu/left-nav/left-nav.scss";
@import "menu/right-nav/right-nav.scss";
@import "main/connector/dialogs/mapping-dialog.scss";
@import "main/connector/wizard/connector-wizard.scss";
@import "main/customsummary/views/customsummary.scss";
@import "main/dqe/editor/dqe-list.scss";
@import "main/summarygroups/views/summarygroups.scss";
@import "main/360/views/list/a360-list.scss";
@import "main/connector/input-connectors/mapping/column-mapping.scss";
@import "main/udmp/views/udmp-list/udmp-list.scss";
@import "menu/menu.scss";
// endinjector
md-toolbar {
  &.md-gray-toolbar {
    background-color: rgb(250, 250, 250);
    h4, h3, span, p {
      color: #404040;
    }
  }
}

md-dialog {
  min-width: 500px;
  &.fullscreen-dialog {
    max-width: 90%;
    max-height: 90%;
    width: 90%;
    height: 90%;
    border-radius: 0;
  }
}

.vega-header {
  background-color: white !important;
  color: #222222 !important;
}

#content-main, #content-container {
  background-color: lightgray;
}

md-list-item {
  &.selected {
    background: #E3F2FD;
    border: 1px solid gray;
  }
}

md-steppers-canvas {
  .md-visually-hidden {
    visibility: hidden;
  }
}

.animate-slide-down {
  transition-duration: 0.8s;
}

.toast-top-center {
  width: 300px;
  margin: 0 auto;
  left: 0;
}

.no-wrap {
  white-space: nowrap;
}

.bold {
  font-weight: 600;
}
.text-deleted {
  text-decoration: line-through;
}

.float-right {
  float: right
}

.editor {
  padding: 20px;
  height: 100%;
  overflow: hidden;

  .editor-list {
    height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
    overflow: auto;

    .list-item {
      &.selected {
        background-color: #d0dee9;
        border: 1px solid gray;
      }

      &.checked {
        background: #FFF8E1;
      }

    }
  }

  .editor-topbar {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
  }

}

.search-field {

  padding-left: 10px;

  background: #FFFFFF;

  .icon {

    padding-top: 3px;
  }

  input {
    padding-left: 16px;
    color: rgba(0, 0, 0, 0.54);
  }
}

.editor-dialog {
  .list-container {
    max-height: 450px;

    .list-item {
      &.selected {
        background-color: #d0dee9;
        border: 1px solid gray;
      }

      &.checked {
        background: #FFF8E1;
      }

    }

  }

  .form-container {
    max-height: 380px;
  }
}

md-autocomplete.no-float {
  md-autocomplete-wrap {
    md-input-container {
      margin-top: 0px;

      label {
        -webkit-transform: translate3d(1px, 28px, 0) scale(1) !important;
        transform: translate3d(1px, 28px, 0) scale(1) !important;
        color: #BDBDBD; // input placeholder gray
      }

      &.md-input-has-value {
        label {
          display: none !important;
        }
      }
    }
  }
}

.mb-17 {
  margin-bottom: 17px;
}

.hint {
  /* Position the hint */
  max-height: 30px;
  position: absolute;
  left: 2px;
  right: auto;
  bottom: -6px;
  /* Copy styles from ng-messages */
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  /* Set our own color */
  color: grey;
}

.hint.ng-hide,
.hint.ng-enter,
.hint.ng-leave.ng-leave-active {
  bottom: 26px;
  opacity: 0;
}

.hint.ng-leave,
.hint.ng-enter.ng-enter-active {
  bottom: 7px;
  opacity: 1;
}

.chip {
  margin-left: 10px;
  display: inline-block;
  padding: 0 10px;
  height: 25px;
  font-size: 12px;
  line-height: 25px;
  border-radius: 15px;
  background: #e9eff5;
  border: 1px solid #b6b6b6;
}

%custom-textarea {
  border: 1px solid gray !important;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

textarea {
  &.vh-55 {
    max-height: 55vh;
  }

  &.textarea-form {
    @extend %custom-textarea;
    min-height: calc(100vh - 450px) !important;
    max-height: calc(100vh - 450px) !important;
  }
  &.textarea-300 {
    @extend %custom-textarea;
    height: 300px;
    min-height: 300px !important;
  }
  &.long-textarea {
    @extend %custom-textarea;
    height: 100px;
    min-height: 100px !important;
  }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container{
  min-width: 400px !important;
}

.grecaptcha-badge { visibility: hidden; }

.text-danger {
  color: red;
}