
.connector-list .list-group {
  margin-bottom: 0;

}

.entity-column-list {
  max-height: 64vh;
  overflow: auto;
}

.ui-data-mapper {
  overflow: auto;
}

.entity-column-list .list-group {
  margin-bottom: 0;

}

.entity-column-list .list-group-item {
  color: #fff;
  background-color: #337ab7;
}

.ui-data-mapper .column {
  border: solid 1px darkgrey;
  box-shadow: rgba(0, 0, 0, 0.7) 0 1px 10px 0;
  min-height: 300px;
}

.connector-list .ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  min-width: 150px;
}

.connector-selector {
  padding-bottom: 10px;
}

input.param-input {
  border: 1px solid gray !important;
  width: 100%;
  min-height: 30px;
}

textarea.param-input {
  border: 1px solid gray !important;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 30px;
  min-height: 30px;
}

.connector-scheduler {
  overflow-x: hidden;
}

.fb-ad-accounts-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
  0 13px 19px 2px rgba(0, 0, 0, 0.14),
  0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 400px;
}

.ad-account-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0 16px;
  position: relative;
  transition: background 0.15s linear;
  width: auto;
}

.ad-account-item:hover,
.ad-account-item:focus {
  background-color: rgb(238, 238, 238);
}

.ad-account-item.selected {
  color: rgb(16, 108, 200);
}

.connector-details-dialog {
  min-width: 600px;
  min-height: 500px;
}

.history-table {
  height: calc(60vh - 150px);
}

.connector-alerts {
  .ml-20 {
    margin-left: 20px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .ml-25 {
    margin-left: 25px;
  }

  .mr-45 {
    margin-right: 45px;
  }

  .w-15em {
    width: 15em;
  }
}
table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 18px 0 0 !important;
}
.list-tooltip {
  position: relative;
    z-index: 1;
}
.destination-list-item {
  .md-icon-button {
    margin: 1px !important;
  }
    div.md-button:first-child {
        padding: 0 5px !important;
    }
}
.connector-image {
  background-color: #eeeeee;
  img{
    max-height: 210px;
  }
}
.destination_error{
  color:#e74c3c;
  font-size: 12px;
  padding-top: 15px;
}
