#user-list, #role-list {
  max-height: calc(100vh - 220px);
  overflow: auto;
}

.user-edit-form, .role-edit-form {
  max-height: calc(100vh - 160px);
  overflow: auto;
  .include-role-invalid {
    color: red;
  }
  .user-roles-autocomplete input {
    min-width : 350px;
  }
}
