#dqe-editor {
  overflow: auto;
  min-height: 90vh;
  padding: 14px 0;
  // Center
  .center {

    .content-card {
      .search {
        height: 36px;
        padding-left: 10px;
        line-height: 36px;
        background: #FFFFFF;

        .icon {
          margin: 0;
        }

        input {
          padding-left: 16px;
          height: 36px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
      .toolbar {
        position: relative;
        padding: 8px 16px;

        .page-info {
          font-weight: 500;
          margin: 0 8px;
          white-space: nowrap;
        }
      }

      .content-wrapper {
        overflow: hidden;
        position: relative;
        padding: 0;
        .content {
          padding: 0;

          &.dqe-editor-list-pane {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
            background: #FAFAFA;
          }

        }
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-lg) {

  #dqe-editor {

    .center {

      .content-card {
        .content-wrapper {
          padding: 0;
          .content {
            padding: 0;

          }
        }

        md-checkbox {
          padding-left: 0;
        }

      }

    }
    md-button {

      &.md-icon-button {
        width: 20px;
        margin: 0 3px;
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  #dqe-editor {
    md-button {
      &.md-icon-button {
        margin: 0 6px;
      }
    }

  }
}
