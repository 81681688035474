#summaryGroupsEditForm {

  md-input-container.groupInputContainer {
    width: 13em;
  }
  md-input-container label {
    min-width: 200px;
  }
}




