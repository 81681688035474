#udmp-editor {

  .udmp-list-item {
    &.selected {
      background-color: #d0dee9;
      border : 1px solid gray;
    }

    &.checked {
      background: #FFF8E1;
    }

  }

}
