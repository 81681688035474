.search-field {

  padding-left: 10px;

  background: #FFFFFF;

  .icon {

    padding-top : 3px;
  }

  input {
    padding-left: 16px;
    height: 36px;
    color: rgba(0, 0, 0, 0.54);
  }
}
.udmp-table-list, .udmp-column-list {
  max-height: calc(100vh - 260px);
  overflow : auto;
}
#udmp-editor {
  height: 100%;
  overflow: hidden;
}
#udmp-editor-topbar {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
}

md-input-container.largeErrorSpace {
  .md-errors-spacer {
    height: 37px;
  }
}

.hint.moveUp {
  bottom: 8px;
}
.text-warning {
  color: #ffc107!important;
}
.text-danger {
  color: #dc3545!important;
}

.properties-table {
  width: 100%;
  th {
    text-align: left;
  }
  md-input-container {
    margin: 0 !important;
    width: 95%;
  }
  .deleteProperty{
    position: relative;
    top:-10px;
  }
}
.text-center {
 text-align: center;
}
