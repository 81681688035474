

#workflow-canvas {
  > canvas {
    z-index: 1;
  }
}

#cy {
  height: 90vh;
  width: 100%;
  position: absolute;
  background-color: #f0f0f0;
  canvas {
    z-index: 1 !important;

  }
}


.blue {
  color: #0066cc;
}

.ng-aside.horizontal.right .modal-dialog {
  top: 60px;

}

#workflow-list{
  height: calc(100vh - 60px);
}

.move-up {
  position: relative;
  top: -15px;
}
mdp-time-picker div .md-icon-button {
  width: 50px !important;
}

