.filters-list, .filter-detail-list{
   max-height: calc(100vh - 193px);
   overflow: auto;
}
.template-editor-list-item {
   md-checkbox {
      margin-bottom: 0;
   }
}
.md-list-item-inner {
   md-checkbox {
      margin-right: 10px !important;
   }
   md-icon {
      margin-right: 10px !important;
   }
}