.search-field {

  padding-left: 10px;

  background: #FFFFFF;

  .icon {

    padding-top: 3px;
  }

  input {
    padding-left: 16px;
    height: 36px;
    color: rgba(0, 0, 0, 0.54);
  }
}

.udmp-table-list, .udmp-column-list {
  max-height: calc(100vh - 260px);
  overflow: auto;
}

#360-editor {
  height: 100%;
  overflow: hidden;
}

#udmp-editor-topbar {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
}

.column-dialog-error {
  width: 432px;
  min-width: 432px;
  max-width: 432px;
}

.validate360FailIcon {
  color: red;
}

.validate360SuccessIcon {
  color: green;
}
