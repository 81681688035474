

.connect-def-selector {
  md-whiteframe {
    background: #fff;
    margin: 30px;
    max-height: 140px;
    max-width: 140px;
    &.selected {
      background-color: rgb(84, 110, 122);
      color: white;
    }
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }
}

.connector-def-grid input.param-input {
  border: 1px solid gray;
  width: 100%;
  min-height: 30px;
}

.connector-wizard  {
  md-list-item {
    &.selected {
      background-color: #d0dee9;
      border : 1px solid gray;
    }

    &.checked {
      background: #FFF8E1;
    }

  }
  .connector-step-content{
    max-height: calc(100vh - 290px);
    overflow : auto;
  }
  md-steppers {
    overflow: auto;
    md-steppers-content-wrapper {
      overflow: hidden;
    }
  }
}

.udmp-select-table-list, .udmp-select-column-list {
  max-height: calc(100vh - 430px);
  overflow: auto;
}

