#overrides
{
  .listHeader
  {
    border-bottom: 1px solid lightgrey;
  }

  .md-errors-spacer
  {
    display: none !important;
    height: 0px;
    max-height:0px;
  }

  .fixedCol
  {
    width:100px;
    min-width: 100px;
    max-width: 100px;

  }

  .wideCol
  {
    width:250px;
    min-width: 250px;
    max-width: 250px;
  }

  .wideHead
  {
    width:250px;
    min-width: 250px;
    max-width: 250px;
  }


  .fixedInput
  {
    width:50px;
    min-width: 50px;
    max-width: 50px;
  }

  .groupContainer {
    max-width: 700px;
    overflow: auto;
  }
}

#helpOverridesCloseButton
{
  position: relative;
  top:10px;
}



  #overrideHelpTable
  {
    td { padding: 5px 20px 5px 20px; }
    th { padding: 5px 20px 5px 20px; }

    #overrideHelpHeader
    {
      text-align:left;
    }

    #overrideHelpTableHeader
    {
      background-color: lightgrey;
    }

    .overrideHelpTableData
    {
      background-color: #f0f0f0
    }

  }


