#vertical-navigation {

  width: $navigationWidth;
  min-width: $navigationWidth;
  max-width: $navigationWidth;
  overflow-x: hidden;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: $whiteframe-shadow-6dp;

  i {
    padding-right:5px;
    padding-left:5px;
  }


  .left-nav-menu {
    height: 100%;
  }
  .navigation-header {
    height: $toolbarHeight;
    min-height: $toolbarHeight;
    background-color: white;

    .logo {
        background-color: white;
      .logo-image {
        display: block;
        width: $navigationFoldedWidth;
        min-width: $navigationFoldedWidth;
        height: $toolbarHeight;
        line-height: $toolbarHeight;
        background-color: orangered;
      }

    }

  }
  .nav-button {
     text-align: left;
    width: $navigationWidth;
    min-width: $navigationWidth;
    max-width: $navigationWidth;
  }

  .subnav-button {
    text-align: left;
    padding-left:5px;
    width: $navigationWidth;
    min-width: $navigationWidth;
    max-width: $navigationWidth;
  }

  &:not(._md-locked-open) {
    box-shadow: $whiteframe-shadow-8dp;
  }
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {

  .left-nav-folded {

    #content-container {
      margin-left: $navigationFoldedWidth;
    }

    #vertical-navigation {
      position: absolute;

      .navigation-header {

        .fold-toggle {
          transform: rotate(180deg);
          opacity: 0;
        }
        .logo-text {
          width: 75%;        
          height: auto;
          padding-left: 2px;         
        }
      }

      .menu-active {
        color : orangered;
      }
    }

    &.left-nav-folded-open {

      #vertical-navigation {
        position: absolute;
        transition-delay:0.5s;
        .navigation-header {

          .fold-toggle {
            transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
            opacity: 1;
          }
        }
        .left-menu-item-text {
          transition-delay:0.5s;
          visibility: visible;
        }

        .subnav-button {
          text-align: left;
          padding-left:30px;
          width: $navigationWidth;
          min-width: $navigationWidth;
          max-width: $navigationWidth;
        }

      }


    }

    &:not(.left-nav-folded-open) {

      #vertical-navigation {
        position: absolute;

        width: $navigationFoldedWidth;
        min-width: $navigationFoldedWidth;
        max-width: $navigationFoldedWidth;

        .navigation-header {

          .logo-text {
            visibility: hidden;
          }
        }
        .left-menu-item-text {
          visibility: hidden;
        }
      }
    }
  }
  &:not(.left-nav-folded) {


  }

}

/*----------------------------------------------------------------*/
/*  left-nav
/*----------------------------------------------------------------*/

.left-nav {

  display: block;
  position: relative;
  color:  #FFFFFF;
  user-select: none;
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {

  body {

    &.left-nav-folded {

      &:not(.left-nav-folded-open) {

        .left-nav {

          .animate-height {
            transition: none;
          }


        }

        #left-nav-expand {
          position: absolute;
          top: $toolbarHeight;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 999;
        }
      }

      #left-nav-collapse {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 59;
      }
    }
  }
}


