#dqe-editor {
  .dqe-editor-list-pane {
    .dqe-editor-list-item {
      &.selected {
        background: #E3F2FD;
        border : 1px solid gray;
      }
      &.checked {
        background: #FFF8E1;
      }

    }
  }
}
.as-sortable-item, .as-sortable-placeholder{
  display: flex;
}
