#customSummaries {

  #customSummaryEditForm {
    md-input-container label {
      min-width: 200px;
    }
  }
  #summaryList {
    max-height: calc(100vh - 100px);
    overflow : auto;
  }
}


