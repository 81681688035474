/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/

// Typography
$font-family-body: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
$font-family-code: 'Monaco', 'Menlo', 'Consolas', 'Ubuntu Mono', monospace;

// Toolbar
$toolbarHeight: 54px;


// Navigation Sidenav
$navigationWidth: 250px;
$navigationFoldedWidth: 54px;
