#status-list {
  height: calc(100vh - 60px);
  md-input-container .status-filter {
    background: white;
  }
}
.status-detail-dialog {
  .status-logs {
    min-width:  calc(100vw - 200px);
    font-size: 12px;
  }
}
.status-list-table {
  height: calc(100vh - 225px);
}
