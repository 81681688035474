/* AngularJS Form validation styling */
.validation.help-block {
  color: #E07676;
  font-style: italic;
}

/* invalid & (dirty or touched) => red -- CSS3 only */
.ng-invalid.ng-dirty:not(:focus),
.ng-invalid.ng-touched:not(:focus) {
  border-color: #e74c3c;
}

/* valid & dirty => green */
.ng-valid.ng-dirty.ng-touched {
  border-color: #2ecc71;
}

/* error display font italic, text-danger is red in BS */
.validation.text-danger {
  font-style: italic;
  color: #f98787;
}
