#logoContainer
{
  width: 384px;
  padding: 36px 0 0 41px;
  text-align: left;
  img{
    width: 100%;        
  }
}
#login
{
  background-color: #F7F7F7;
  #login-form {
    max-width: 384px;
    min-width: 384px;
    width: 384px;    
    margin: auto;
    box-shadow: none;
    .md-button {
      width: 250px !important;
    }
  }    
}
.forgot-password-form {
  margin: auto;
  padding: 20px;
}

.rightPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 500px;
  padding: 0;
  background-image: url('../assets/backgrounds/default-login-background.svg');
  background-position: center;
  background: #1B7AC0;
}
.rightPanel {
.InfoContainer {
  padding: 20px;
  display: block;
  max-width: 650px;
  width: 70%;
  text-align: left;
  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;      
}
.logo img {
  max-height: 110px;
  height: 100%;
}
h4 {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
    margin-top: 13px;
}
.more-btn {
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 16px 24px;
  text-decoration: none;
  &:hover {
   background: rgba(20, 26, 31, 0.35);
  }
  span {
    color: #FFFFFF !important;
    font-size: 14px !important;
    margin-left: 10px;
  }
}
}

}

#login-form {
  .title {
    font-size: 30px;
    font-weight: bold;
    color: #141a1f;
  }
  .md-input-message-animation {
    text-align: left;
  }
  md-input-container {
    label {
      text-align: left;
      color: #141a1f;
       font-size: 14px;
    }
  }
}
.button-link {
  color: #52697a!important;
  font-size: 14px!important;
}
.bnt-navy {
  background-color: #232c61!important;
  color: #fff!important;
}
.bnt-navy:hover {
  background-color: #141a38!important;
}
#loginFooter {
  font-size: 12px;
  width: 100%;
  padding: 8px 41px 30px;
  color: #52697a;
  text-align: left;
}


