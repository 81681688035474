#mapping-template-maintenance {
  .template-list {
    overflow: auto;
    max-height: calc(100vh - 200px);
    height: calc(100vh - 200px);
  }
  .template-mapping-detail {
    overflow: auto;
    max-height: calc(100vh - 250px);
    height: calc(100vh - 250px);
  }
}
