.squery-wizard {

  .content-view {
    height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
  }

  md-tabs.defs-tab {
    height: calc(100vh - 330px);
    max-height: 100%;
    md-tabs-content-wrapper {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .mini-content-view {
    overflow: auto;
    height: calc(100vh - 370px);
    max-height: calc(100vh - 370px);
  }
}

.scroll {
  overflow: auto;
}

.import-dialog {
  width: 600px;
  max-width: 600px;
  max-height: 650px;
}

.inactive-report {
  span {
    color: grey;
  }
}

.action-column {
  min-width: 15em;
}
